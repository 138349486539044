<template>
  <!-- NAME[epic=采购] 采购汇总按商品 -->

  <div style="padding: 20px">
    <el-row>
      <el-form ref="form" inline :model="form">
        <el-form-item prop="class_id">
          <el-select
            v-model="form.class_id"
            placeholder="请选择品类"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in Category"
              :key="index"
              :label="item.class_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="brand_id">
          <brand-select
            ref="brandSelect"
            :duoxuan="true"
            style="width: 160px"
            @brand-select-change="brandChange"
          ></brand-select>
          <!-- <el-select
            v-model="form.brand_id"
            placeholder="请选择品牌"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in Brand"
              :key="index"
              :label="item.brand_name"
              :value="item.id"
            ></el-option>
          </el-select> -->
        </el-form-item>
        <el-form-item prop="supp_id">
          <el-select
            v-model="form.supp_id"
            placeholder="请选择供应商"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in supplier"
              :key="index"
              :label="item.supp_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="depot_id">
          <el-select
            v-model="form.depot_id"
            placeholder="请选择仓库"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in warehouse"
              :key="index"
              :label="item.depot_name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="goodsname">
          <!-- <el-input
            v-model="form.keyword_goods"
            placeholder="商品名称，简拼"
          ></el-input> -->
          <goodslist
            :f-key="form.goodsname"
            @select-goods-all="selectGoods($event)"
          ></goodslist>
        </el-form-item>

        <el-form-item prop="Time">
          <el-date-picker
            v-model="form.Time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
        </el-form-item>
        <!-- <el-form-item prop="type">
          <el-select
            v-model="form.type"
            placeholder="请选择类型"
            style="width: 120px"
          >
            <el-option
              v-for="(item, index) in Typesof"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="allot_code">
          <el-input v-model="form.allot_code" placeholder="单据号"></el-input>
        </el-form-item>

        <el-form-item prop="keyword_cust">
          <el-input
            v-model="form.keyword_cust"
            placeholder="备注"
            style="width: 120px"
          ></el-input>
        </el-form-item> -->

        <el-form-item>
          <el-button type="primary" @click="handlersver">查 询</el-button>
          <el-button type="warning" @click="handlerReset">重 置</el-button>
          <el-button @click="handleExport">导 出</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row>
      <el-table
        stripe
        :data="tableData"
        :summary-method="getSummaries"
        show-summary
      >
        <el-table-column align="center" width="50">
          <!-- <template slot="header">
            <el-popover popper-class="custom-table-checkbox" trigger="hover">
              <el-checkbox-group v-model="checkList">
                <el-checkbox
                  v-for="(item, index) in colemd"
                  :key="index"
                  :label="item.label"
                ></el-checkbox>
              </el-checkbox-group>
              <el-button slot="reference" type="text">
                <vab-remix-icon icon="settings-line" />
              </el-button>
            </el-popover>
          </template> -->
          <!-- <template #default="{ row, $index }">
            <div v-if="row.bill_code == '合计'"></div>
            <div v-else>{{ $index + 1 }}</div>
          </template> -->
        </el-table-column>
        <el-table-column
          v-for="(list, index) in colemd"
          :key="index"
          :label="list.label"
          :align="list.align"
          :prop="list.prop"
          width=""
        ></el-table-column>
      </el-table>
      <!-- <sum ref="sum" :tab-column="sumColumn"></sum> -->
    </el-row>
    <el-row type="flex" class="row-bg" justify="end">
      <el-pagination
        background
        :current-page="form.pageNo"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </el-row>
  </div>
</template>

<script>
  import goodslist from '@/components/goodslist/index.vue'
  import { downloadFile, postAction } from '@/api/Employee'
  import BrandSelect from '@/baseComponents/brandSelect'

  // import Sum from '@/baseComponents/sum'
  export default {
    components: {
      goodslist,
      BrandSelect,
    },
    data() {
      return {
        form: {
          class_id: '', //品类ID
          brand_id: '', //品牌ID
          supp_id: '', //供应商ID
          depot_id: '', //仓库ID
          goodsname: '',
          goods_id: '', //商品id
          Time: [],
          start_time: '', //开始时间，时间戳
          end_time: '', //结束时间，时间戳
          pageNo: 1,
          pageSize: 10,
        },
        total: 0,
        warehouse: [],
        Category: [],
        Brand: [],
        supplier: [],
        url: {
          warehouse: '/baseAdmin/common/depot-list',
          Category: '/baseAdmin/common/goods-class-list',
          Brand: '/baseAdmin/common/brand-list',
          list: '/purchaseAdmin/summary/summary-goods',
          supplier: '/purchaseAdmin/base/supp-list',
        },
        tableData: [],
        checkList: [
          '商品名称',
          '条形码',
          '采购数量',
          '采购金额',
          '赠送数量',
          '采退数量',
          '退货金额',
        ],
        colemd: [
          {
            order: 0,
            label: '商品编码',
            align: 'center',
            prop: 'goods_id',
            width: '100',
          },
          {
            order: 1,
            label: '商品名称',
            align: 'center',
            prop: 'goods_name',
            width: '',
          },
          {
            order: 2,
            label: '条形码',
            align: 'center',
            prop: 'goods_code',
            width: '',
          },
          {
            order: 3,
            label: '采购数量',
            align: 'center',
            prop: 'goods_num',
            width: '',
          },
          {
            order: 4,
            label: '采购金额',
            align: 'center',
            prop: 'goods_money',
            width: '',
          },
          {
            order: 5,
            label: '赠送数量',
            align: 'center',
            prop: 'gift_num',
            width: '',
          },
          {
            order: 6,
            label: '采退数量',
            align: 'center',
            prop: 'return_num',
            width: '',
          },

          {
            order: 7,
            label: '退货金额',
            align: 'center',
            prop: 'return_money',
            width: '',
          },
        ],
        footer: {},
        // 合计
        sumColumn: [],
      }
    },
    computed: {
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.colemd.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {
      'form.Time'(val) {
        console.log(val)
        try {
          this.form.start_time = val[0]
          this.form.end_time = val[1]
        } catch (error) {
          this.form.start_time = ''
          this.form.end_time = ''
        }
      },
      'form.goodsname'(val) {
        if (val == '') {
          this.form.goods_id = ''
        }
      },
    },
    mounted() {
      this.handlerwarehouse()
      this.handlerCategory()
      this.handlerBrand()
      this.handlerlist()
      this.handlersupplier()
    },
    methods: {
      selectGoods(row) {
        console.log(row, '我是啥')
        try {
          this.form.goods_id = row.goods_id
        } catch (error) {}
      },
      getSummaries(param) {
        const { columns, data } = param
        const sums = []
        columns.forEach((column, index) => {
          if (index === 0) {
            sums[index] = '合计'
            return
          }

          sums[5] = this.footer.page_sum_money
          sums[8] = this.footer.page_return_money
          // sums[10] = this.footer.balance_count

          // const values = data.map((item) => Number(item[column.property]))
          // if (!values.every((value) => isNaN(value))) {
          //   sums[index] = values.reduce((prev, curr) => {
          //     const value = Number(curr)
          //     if (!isNaN(value)) {
          //       return prev + curr
          //     } else {
          //       return prev
          //     }
          //   }, 0)
          //   sums[index] += ' 元'
          // } else {
          //   sums[index] = 'N/A'
          // }
        })

        return sums
      },
      //供应商
      handlersupplier() {
        postAction(this.url.supplier, {})
          .then((res) => {
            console.log(res, '供应商')
            this.supplier = res.data.rows
          })
          .catch((err) => {
            console.log(err, '')
          })
      },

      //仓库
      handlerwarehouse() {
        postAction(this.url.warehouse, { pageSize: -1 })
          .then((res) => {
            console.log(res, 'lalal')
            this.warehouse = res.data
          })
          .catch((err) => {
            console.log(err, '')
          })
      },
      //品类
      handlerCategory() {
        postAction(this.url.Category, { pageSize: -1 })
          .then((res) => {
            console.log(res, '品类')
            this.Category = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },
      //品牌
      handlerBrand() {
        postAction(this.url.Brand, { pageSize: -1 })
          .then((res) => {
            console.log(res, '品牌')
            this.Brand = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },

      handleSizeChange(val) {
        console.log(`每页 ${val} 条`)
        this.form.pageSize = val
        this.handlerlist()
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`)
        this.form.pageNo = val
        this.handlerlist()
      },
      handlersver() {
        this.form.pageNo = 1
        this.handlerlist()
      },
      handlerReset() {
        this.$refs['form'].resetFields()
        this.$refs.brandSelect.resetForm()

        this.handlerlist()
      },
      handlerlist() {
        var data = JSON.parse(JSON.stringify(this.form))
        delete data.Time
        delete data.goodsname
        postAction(this.url.list, data)
          .then((res) => {
            console.log(res)
            this.tableData = res.data
            this.total = res.totalCount
            this.footer = res.ext
            this.tabSum()
          })
          .catch((err) => {
            console.log(err)
          })
      },
      tabSum() {
        // 合计
        this.sumColumn = []
        this.colemd.forEach((item, idx) => {
          if (idx == 3) {
            this.sumColumn.push({
              label: this.footer.sum_money,
            })
          } else if (idx == 6) {
            this.sumColumn.push({
              label: this.footer.return_money,
            })
          } else {
            this.sumColumn.push({
              label: '',
            })
          }
        })
      },
      brandChange(val) {
        this.form.brand_id = val
      },
      handleExport() {
        downloadFile(
          '/purchaseAdmin/summary/summary-goods-export',
          '采购汇总(按商品).xlsx',
          this.form
        )
      },
    },
  }
</script>

<style></style>
